/**
 * helper function to convert an rem value into a pixel unit
 * This is base on the change font-size on the html to 62,5% (= 10px).
 */
/* for icons, texts and links */
/* buttons */
/* buttons */
@keyframes loadingPulseAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.75;
  }
}
/* for icons, texts and links */
/* buttons */
/* buttons */
:root {
  --hls-color-elevation: 0, 0, 0;
}