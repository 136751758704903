@import './styles/base';
@import './styles/loading-container';
@import '@angular/material/prebuilt-themes/indigo-pink.css';


iframe {
  .userflowjs-bubble-toolbar-button {
    display: none !important;
  }
}
