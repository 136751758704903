@use "global-vars" as *;

* {
  font-family: Roboto, Arial, sans-serif;
}

a {
  transition: all .2s ease;
  cursor: pointer;
}

html {
  font-size: 62.5%;
  line-height: 1.15;
}

body {
  margin: 0;
  background: linear-gradient(180deg, #ffffff 0%, $ts-color-blue-50 100%);
  color: #000a12;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-feature-settings: 'kern', 'liga';
}
